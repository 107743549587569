





























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import User from '@/store/entities/admin/user';
import FormBase from '@/components/helper/form-base.vue';


@Component({
    components: { FormBase }
})
export default class AddressForm extends ListBase {
    @Prop({ type: Object }) model: object;
    @Prop({ type: String, default: '' }) title: string;
    @Prop({ type: String, default: '' }) icon: string;
            
    items = [
        this.gItem({ type: 'text', prop: 'address1', label: 'Direccion', preicon: 'home', size: 6 }),
        this.gItem({ type: 'text', prop: 'address2', label: 'Referencia', preicon: 'home', size: 6 }),
        this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', entity: 'country', returnObject: false, size: 3 }),
        this.gItem({ type: 'ubigeo', prop: 'ubigeo', entity: 'department', returnObject: true, size: 9, filterParam: 'country_id', filterVal: [0] }),
        this.gItem({ type: 'phone', prop: 'phone', label: 'Telefono', size: 6 }),        
        this.gItem({ type: 'spacer', size: 6 }),        
    ]

    get loading() {
        return this.$store.state.lawyer.loading;
    }

    get user() {
        return this.$store.state.session.user;
    }

    get disabledUpd() {
        var today = new Date();
        if ((this.model as any).updated_at == undefined) return false;
        console.log(today.toISOString().substr(0, 10));
        return (this.model as any).updated_at.substr(0, 10) == today.toISOString().substr(0, 10);
    }

    async save() {
        if ((this.$refs.vform as any).validate()) {
            let model = Util.extend(true, {}, this.user);
            //this.$emit('save-success');
            if (model.address != null && model.address.ubigeo != undefined && model.address.ubigeo != null) {
                model.address['department_id'] = model.address.ubigeo.department;
                model.address['province_id'] = model.address.ubigeo.province;
                model.address['district_id'] = model.address.ubigeo.district;
            }

            if (model.address_work != null && model.address_work.ubigeo != undefined && model.address_work.ubigeo != null) {
                model.address_work['department_id'] = model.address_work.ubigeo.department;
                model.address_work['province_id'] = model.address_work.ubigeo.province;
                model.address_work['district_id'] = model.address_work.ubigeo.district;
            }

            if (model.address_legal != null && model.address_legal.ubigeo != undefined && model.address_legal.ubigeo != null) {
                model.address_legal['department_id'] = model.address_legal.ubigeo.department;
                model.address_legal['province_id'] = model.address_legal.ubigeo.province;
                model.address_legal['district_id'] = model.address_legal.ubigeo.district;
            }
            
            let result = await this.$store.dispatch({
                type: 'lawyer/update',
                data: model
            });

            var today = new Date();
            (this.model as any).updated_at = today.toISOString().substr(0, 10);

            this.$toast.success('La Direccion ha sido actualizada');
        } else
            return false;
    }

    mounted() {

    }

    created() {
        
    }
}