







































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import User from '@/store/entities/admin/user';
import FormBase from '@/components/helper/form-base.vue';


@Component({
    components: { FormBase }
})
export default class UserProfile extends ListBase {
    //user: User = new User();
    valid2: boolean = false;
    showPass: boolean = false;
    tabModel: any = null;
    rules: any = {
        pass_req: value => !!value || 'El Password es obligatoria.',
        min: v => (v && v.length >= 8) || 'Minimo 8 caracteres',
        match: v => (v == this.user.password) || 'Las contrasenas no Coinciden',
        emailMatch: () => ('The email and password you entered don\'t match'),
    };

    items = [
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', required: true, dense: false, size: 4 }),
        this.gItem({ type: 'text', prop: 'lastname1', label: 'Apellido Paterno', required: true, dense: false, size: 4 }),
        this.gItem({ type: 'text', prop: 'lastname2', label: 'Apellido Materno', size: 4, dense: false }),
        this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Numero de Telefono', size: 6, dense: false }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6, dense: false }),            
    ]

    get loading() {
        return this.$store.state.lawyer.loading;
    }
    get user() {
        return this.$store.state.session.user;
    }
    set user(value) {
        //
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    async save() {
        if ((this.$refs.vform as any).validate()) {
            let result = await this.$store.dispatch({
                type: 'lawyer/update',
                data: this.user
            });
            
            Util.abp.notify.success(this, 'Tu perfil ha sido actualizado');
            //this.$toast.success('Tu perfil ha sido actualizado');
        } else
            return false;
    }

    async updatePassword() {
        if ((this.$refs.vform2 as any).validate()) {
            let newModel = await this.$store.dispatch({
                type: 'lawyer/updatePassword',
                data: {
                    id: this.user.id,
                    password_old: this.user.password_old,
                    password: this.user.password
                }
            });
            (this.$refs.vform as any).reset();

            Util.abp.notify.success(this, 'Tu Password ha sido actualizado');

            return newModel;
        }
    }

    mounted() {

    }

    async created() {
        
    }
}