



































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import EditForm from './edit.vue';
import AddressForm from './address.vue';


@Component({
    components: { FormBase, EditForm, AddressForm }
})
export default class UserProfile extends ListBase {
    tab: any = null;
    text: string = '';
    formBase: boolean = false;

    get loading() {
        return this.$store.state.user.loading;
    }
    get user() {
        return this.$store.state.session.user;
    }
    get userName() {
        return this.$store.state.session.user ? this.$store.state.session.user.firstname : ''
    }
    
    async created() {
    }
}